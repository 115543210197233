// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-english-tsx": () => import("./../../../src/pages/english.tsx" /* webpackChunkName: "component---src-pages-english-tsx" */),
  "component---src-pages-esittely-tsx": () => import("./../../../src/pages/esittely.tsx" /* webpackChunkName: "component---src-pages-esittely-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-johtaja-tsx": () => import("./../../../src/pages/johtaja.tsx" /* webpackChunkName: "component---src-pages-johtaja-tsx" */),
  "component---src-pages-konsertit-tsx": () => import("./../../../src/pages/konsertit.tsx" /* webpackChunkName: "component---src-pages-konsertit-tsx" */),
  "component---src-pages-tilaa-laulua-tsx": () => import("./../../../src/pages/tilaa-laulua.tsx" /* webpackChunkName: "component---src-pages-tilaa-laulua-tsx" */)
}

