module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kamarikuoro Kaamos","short_name":"Kaamos","lang":"fi","start_url":"/","background_color":"#ffffff","theme_color":"#3ca2d5","display":"minimal-ui","icon":"src/images/gasp.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"98772a1fef67a270bbc09d3d86063c62"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"htmlTitle":"Kaamos CMS"},
    }]
